<template>
  <div style="display: flex; justify-content: center; padding-top: 10px">
    <div id="printDiv" :style="`width: ${entity.styleW}cm;`">
      <div
        :style="`position: relative;width: ${entity.styleW}cm;height: ${
          entity.styleH *
          (Number(entity.printPages) > 1 ? Number(entity.printPages) : 1)
        }cm;page-break-after:always`"
      >
        <vue-drag-resize
          v-for="(item, index) in entity.printlist"
          :key="item.Id"
          :isActive="false"
          :preventActiveBehavior="false"
          :isDraggable="false"
          :isResizable="false"
          :aspectRatio="false"
          parentLimitation
          :parentW="getpx(entity.styleW, 'w', 'px')"
          :parentH="
            getpx(
              entity.styleH *
                (Number(entity.printPages) > 1 ? Number(entity.printPages) : 1),
              'h',
              'px'
            )
          "
          :w="item.w"
          :h="item.h"
          :x="item.x"
          :y="item.y"
          :minh="28"
          :minw="80"
        >
          <div
            v-if="item.type === '1'"
            :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
          >
            <div v-if="item.Keys !== 'Time'">{{ item.value }}</div>
            <div v-else>{{ CreateTime }}</div>
          </div>
          <!-- 段落 -->
          <div
            v-if="item.type == '12'"
            class="duanl"
            :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
          >
            <div v-html="item.value"></div>
          </div>
          <!-- 签章 -->
          <div
            class="imgBox"
            :style="`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
            v-if="item.type == '3' && item.Keys == 'cusSeal' && cusSeal"
          >
            <img :src="cusSeal" alt="" />
          </div>
          <div
            :style="`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
            class="imgBox"
            v-if="item.type == '3' && item.Keys == 'comSeal' && comSeal"
          >
            <img :src="comSeal" alt="" />
          </div>
          <div v-if="item.type === '4'" style="width: 100%; height: 100%">
            <table border="1" style="width: 100%; height: 100%">
              <thead style="background-color: #ccc">
                <td
                  v-for="(val, index) in item.columns"
                  :key="val.dataIndex"
                  :style="`width: ${val.width || 50}px;line-height: ${
                    val.lineHeight || 28
                  }px; height: ${val.lineHeight || 28}px; text-align: ${
                    val.align || 'center'
                  };font-weight:${val.bold || 'initial'};font-size:${
                    val.fontSize || 18
                  }px;`"
                >
                  {{ val.title }}
                </td>
              </thead>

              <tr v-for="(itemdata, index) in item.datalist" :key="index">
                <td
                  v-for="(val, valix) in item.columns"
                  :key="valix"
                  :style="`text-align: ${val.textalign || 'center'};`"
                >
                  <div
                    v-if="val.dataIndex == 'Number'"
                    :style="`text-align: ${val.textalign || 'center'};height:${
                      val.textlineHeight || 20
                    }px;overflow: hidden;word-break:break-all;`"
                  >
                    {{ itemdata[val.dataIndex] }}
                  </div>
                  <div
                    v-else
                    :style="`text-align: ${val.textalign || 'center'};height:${
                      val.textlineHeight || 20
                    }px;overflow: hidden;word-break:break-all;`"
                  >
                    {{ resetValue(val, itemdata) }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </vue-drag-resize>
      </div>
    </div>
    <div class="sign-box">
      <div>
        <el-divider></el-divider>
        <!-- 回显签名 -->
        <div v-if="baseData.State != 0" class="title">签名</div>
        <div
          v-if="baseData.State != 0"
          style="position: relative; width: 150px; height: 100px"
        >
          <img class="name-sign" :src="baseData.Sign" alt="" />
        </div>

        <div class="title">签收照片/视频</div>
        <updataImg
          :canEdit="baseData.State == 0"
          :imgListParam="baseData.Imgs"
          :videoParam="baseData.Video"
          @change="updataImgUrl"
        ></updataImg>
      </div>
      <div v-if="baseData.State == 0">
        <el-divider></el-divider>
        <div class="title">签收人</div>
        <nameSign @setBase64Data="setBase64Data" :name="'签名'"></nameSign>
      </div>
    </div>

    <div class="printBox">
      <div
        class="printSon"
        :style="{
          minWidth: entity.styleW + 'cm',
        }"
      >
        <el-button type="primary" @click="getPdf()">下载</el-button>
        <el-button type="primary" v-print="'printDiv'">打印</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import updataImg from "@/components/updataImg.vue";
import nameSign from "@/components/nameSign.vue";

const NumFormatting = function (data, num = 2) {
  if (isNaN(Number(data))) {
    return data;
  } else {
    return Number(data).toFixed(num);
  }
};
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
export default {
  components: {
    updataImg,
    nameSign,
  },
  data() {
    return {
      entity: {},
      htmlTitle: "对账函",
      comSeal: "",
      cusSeal: "",
      CreateTime: "",
      Id: "",
      newarr: [],

      Sign: "", //签字
      Imgs: "", //图片 type string
      Video: "", //视频
      Imgs_back: "", //返回的图片
      Video_back: "", //返回的视频
      baseData: {},
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.$http
      .post(
        "/Base/Base_Reconciliation/GetRecDetail?Id=" + this.$route.query.prop
      )
      .then((res) => {
        console.log(res);
        if (res.Success && res.Data) {
          this.Id = res.Data.Id;
          this.CreateTime = res.Data.CreateTime.substring(0, 10);
          this.comSeal = res.Data.CompanySeal;
          this.cusSeal = res.Data.CustomerSeal;
          this.entity = JSON.parse(res.Data.SpanContent);
          this.baseData = res.Data;
          console.log(this.entity);
          if (res.Data.Content) {
            if (res.Data.Imgs) {
              this.imglist = res.Data.Imgs.split(",");
            } else {
              this.imglist = [];
            }
            this.$nextTick(() => {
              var obj = JSON.parse(res.Data.Content);
              this.printPages = obj.printPages;
              this.styleW = obj.styleW;
              this.styleH = obj.styleH;
              this.printlist = obj.printlist;
              this.printsize = obj.printsize;
              this.isAccessory = obj.isAccessory;
              if (this.printsize === "portrait") {
                this.styleText =
                  '<style type="text/css" media="print">\n' +
                  "  @page { size: portrait; }\n" +
                  "</style>";
              } else {
                this.styleText =
                  '<style type="text/css" media="print">\n' +
                  "  @page { size: landscape; }\n" +
                  "</style>";
              }
              this.data = this.printlist.filter((item) => {
                return item.type === "4";
              });
              var tablearr = JSON.parse(JSON.stringify(this.data));
              var arr = [];
              if (tablearr[0].rowCount) {
                for (
                  var i = 0;
                  i < tablearr[0].datalist.length;
                  i += tablearr[0].rowCount
                ) {
                  console.log(
                    tablearr[0].datalist.slice(i, i + tablearr[0].rowCount),
                    "数据"
                  );
                  arr.push(
                    tablearr[0].datalist.slice(i, i + tablearr[0].rowCount)
                  );
                }
              } else {
                if (obj.keyType === "A4") {
                  for (var i = 0; i < tablearr[0].datalist.length; i += 10) {
                    arr.push(tablearr[0].datalist.slice(i, i + 10));
                  }
                } else {
                  for (var i = 0; i < tablearr[0].datalist.length; i += 5) {
                    arr.push(tablearr[0].datalist.slice(i, i + 5));
                  }
                  console.log(arr);
                }
              }

              var newarr = [];

              arr.forEach((item) => {
                var newobj = JSON.parse(res.Data.Content);
                this.entity.printlist.forEach((val) => {
                  if (val.Key == "发货日期" && val.value) {
                    this.cTime = val.value;
                  }
                  if (val.type === "4") {
                    // val.datalist = [];
                    // this.$set(val, "datalist", item);
                    // let length = val.datalist.length;
                    // console.log(val);
                    // if (val.rowCount) {
                    //   if (length < val.rowCount) {
                    //     for (let i = 0; i < val.rowCount - length; i++) {
                    //       val.datalist.push({});
                    //     }
                    //   }
                    // } else {
                    //   if (obj.keyType === "A4") {
                    //     if (length < 10) {
                    //       for (let i = 0; i < 10 - length; i++) {
                    //         val.datalist.push({});
                    //       }
                    //     }
                    //   } else {
                    //     if (length < 5) {
                    //       for (let i = 0; i < 5 - length; i++) {
                    //         val.datalist.push({});
                    //       }
                    //     }
                    //   }
                    // }
                    // console.log(val.datalist, "datalist");
                  }
                });
                newarr.push(newobj);
              });
              this.printPages = arr.length;
              this.newarr = newarr[0];
              console.log(this.newarr, this.entity);
            });
          } else {
            this.$message.error("暂无信息");
          }
        }
      });
  },
  methods: {
    // 下载
    handleDownload() {
      this.$http
        .post("/Base/Base_Reconciliation/GetRecPDF?id=" + this.Id)
        .then((res) => {
          if (res.Success) {
            downloadFile(res.Data, this.Id);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    resetValue(val, itemdata) {
      console.log(itemdata[val.dataIndex]);
      if (itemdata[val.dataIndex]) {
        if (val.type === "text") {
          return itemdata[val.dataIndex];
        } else if (val.type === "number") {
          return this.NumFormatting(
            itemdata[val.dataIndex],
            Number(val.decimals ? val.decimals : 2)
          );
        } else {
          return this.NumFormatting(itemdata[val.dataIndex], 2);
        }
      } else {
        return itemdata[val.dataIndex];
      }
    },
    NumFormatting(data, num = 2) {
      return NumFormatting(data, num);
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
      // px转cm：cm = pxValue/dpi*2.54
      //           px = cm/2.54*dpi
    },

    setBase64Data(url) {
      this.Sign = url;
    },
    updataImgUrl({ video, imgs }) {
      this.Imgs = imgs || "";
      this.Video = video || "";
    },
  },
};
</script>

<style>
#printDiv .a {
  padding: 0 20px;
  border-bottom: 1px solid #000;
}
#printDiv .duanl {
  text-indent: 2em;
}
.imgBox img {
  width: 100%;
  height: 100%;
}
.printBox {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.printBox .printSon {
  padding: 10px 0;
  text-align: right;
  border-top: 1px solid #ccc;
}

.sign-box {
  padding: 10px;
  .title {
    font-size: 15px;
    font-weight: bold;
  }
}

.name-sign {
  transform: rotate(-90deg);
  transform-origin: left top;
  position: absolute;
  left: 48px;
  top: 50px;
  width: 50px;
}
</style>
