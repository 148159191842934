var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center","padding-top":"10px"}},[_c('div',{style:(`width: ${_vm.entity.styleW}cm;`),attrs:{"id":"printDiv"}},[_c('div',{style:(`position: relative;width: ${_vm.entity.styleW}cm;height: ${
        _vm.entity.styleH *
        (Number(_vm.entity.printPages) > 1 ? Number(_vm.entity.printPages) : 1)
      }cm;page-break-after:always`)},_vm._l((_vm.entity.printlist),function(item,index){return _c('vue-drag-resize',{key:item.Id,attrs:{"isActive":false,"preventActiveBehavior":false,"isDraggable":false,"isResizable":false,"aspectRatio":false,"parentLimitation":"","parentW":_vm.getpx(_vm.entity.styleW, 'w', 'px'),"parentH":_vm.getpx(
            _vm.entity.styleH *
              (Number(_vm.entity.printPages) > 1 ? Number(_vm.entity.printPages) : 1),
            'h',
            'px'
          ),"w":item.w,"h":item.h,"x":item.x,"y":item.y,"minh":28,"minw":80}},[(item.type === '1')?_c('div',{style:(`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`)},[(item.Keys !== 'Time')?_c('div',[_vm._v(_vm._s(item.value))]):_c('div',[_vm._v(_vm._s(_vm.CreateTime))])]):_vm._e(),(item.type == '12')?_c('div',{staticClass:"duanl",style:(`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`)},[_c('div',{domProps:{"innerHTML":_vm._s(item.value)}})]):_vm._e(),(item.type == '3' && item.Keys == 'cusSeal' && _vm.cusSeal)?_c('div',{staticClass:"imgBox",style:(`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`)},[_c('img',{attrs:{"src":_vm.cusSeal,"alt":""}})]):_vm._e(),(item.type == '3' && item.Keys == 'comSeal' && _vm.comSeal)?_c('div',{staticClass:"imgBox",style:(`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`)},[_c('img',{attrs:{"src":_vm.comSeal,"alt":""}})]):_vm._e(),(item.type === '4')?_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('table',{staticStyle:{"width":"100%","height":"100%"},attrs:{"border":"1"}},[_c('thead',{staticStyle:{"background-color":"#ccc"}},_vm._l((item.columns),function(val,index){return _c('td',{key:val.dataIndex,style:(`width: ${val.width || 50}px;line-height: ${
                  val.lineHeight || 28
                }px; height: ${val.lineHeight || 28}px; text-align: ${
                  val.align || 'center'
                };font-weight:${val.bold || 'initial'};font-size:${
                  val.fontSize || 18
                }px;`)},[_vm._v(" "+_vm._s(val.title)+" ")])}),0),_vm._l((item.datalist),function(itemdata,index){return _c('tr',{key:index},_vm._l((item.columns),function(val,valix){return _c('td',{key:valix,style:(`text-align: ${val.textalign || 'center'};`)},[(val.dataIndex == 'Number')?_c('div',{style:(`text-align: ${val.textalign || 'center'};height:${
                    val.textlineHeight || 20
                  }px;overflow: hidden;word-break:break-all;`)},[_vm._v(" "+_vm._s(itemdata[val.dataIndex])+" ")]):_c('div',{style:(`text-align: ${val.textalign || 'center'};height:${
                    val.textlineHeight || 20
                  }px;overflow: hidden;word-break:break-all;`)},[_vm._v(" "+_vm._s(_vm.resetValue(val, itemdata))+" ")])])}),0)})],2)]):_vm._e()])}),1)]),_c('div',{staticClass:"sign-box"},[_c('div',[_c('el-divider'),(_vm.baseData.State != 0)?_c('div',{staticClass:"title"},[_vm._v("签名")]):_vm._e(),(_vm.baseData.State != 0)?_c('div',{staticStyle:{"position":"relative","width":"150px","height":"100px"}},[_c('img',{staticClass:"name-sign",attrs:{"src":_vm.baseData.Sign,"alt":""}})]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("签收照片/视频")]),_c('updataImg',{attrs:{"canEdit":_vm.baseData.State == 0,"imgListParam":_vm.baseData.Imgs,"videoParam":_vm.baseData.Video},on:{"change":_vm.updataImgUrl}})],1),(_vm.baseData.State == 0)?_c('div',[_c('el-divider'),_c('div',{staticClass:"title"},[_vm._v("签收人")]),_c('nameSign',{attrs:{"name":'签名'},on:{"setBase64Data":_vm.setBase64Data}})],1):_vm._e()]),_c('div',{staticClass:"printBox"},[_c('div',{staticClass:"printSon",style:({
        minWidth: _vm.entity.styleW + 'cm',
      })},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getPdf()}}},[_vm._v("下载")]),_c('el-button',{directives:[{name:"print",rawName:"v-print",value:('printDiv'),expression:"'printDiv'"}],attrs:{"type":"primary"}},[_vm._v("打印")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }